@value colors: "../../../../styles/variables/colors.module.css";
@value color-grey500, color-blue300, color-red400 from colors;
@value layout: "../../../../styles/variables/layout.module.css";
@value gap-l from layout;
@value fonts: "../../../../styles/variables/fonts.module.css";
@value font-size-xs, font-size-xxs from fonts;

.wrapper {
    display: flex;
    flex-direction: column;
    gap: gap-l;
    color: color-grey500;
}

.success {
    all: revert;
    margin: 0;

    color: color-blue300;
    font-size: font-size-xs;
}

.mandatory-text {
    color: color-red400;
    font-size: font-size-xxs;
    margin: 0;
}
